<template>
  <div>
    <a-modal
      :visible="visible"
      title="Publish"
      @cancel="handleCancel"
      @ok="handleCreate"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        @submit="handleSubmit"
      >
        <a-form-item>
          <h1>确认执行该规则？</h1>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="primary" html-type="submit"> 确认 </a-button>
          <br/>
          <span>{{code}}</span>
          <br/>
          <a-button type="primary" @click="copyOrderId(code)"> 复制代码 </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["myvisible", "item"],
  data() {
    return {
      mypath: "",
      visible: false,
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      code: "",
    };
  },
  watch: {
    myvisible(n, o) {
      this.visible = n;
      if (this.visible == true) {
        this.code = "control(" + this.item.modelId+",\""+this.item.behavior+"\""+")"+";";
      }
    },
  },
  methods: {
    // 取消填写表单
    handleCancel() {
      this.visible = false;
    },
    //  确认表单
    handleCreate() {
      this.visible = false;
    },
    handleSubmit(e) {
      let this_1 = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          //  console.log('Received values of form: ', values);
          this_1.mypath = window.g.rules_publish;
          console.log("测试publish", this_1.item.behaviour);
          axios({
            method: "get",
            url: this_1.mypath,
            headers: {
              Authorization: localStorage.getItem("Authorization"),
            },
            params: {
              behavior: this_1.item.behavior,
              modelId: this_1.item.modelId,
              //behaviour:this_1.item.behaviour,
            },
          }).then((result) => {
            console.log(result);
            if (result.data.errorCode === 200) {
              this.$message.success("发布规则成功");
            } else if (result.data.errorCode === 401) {
              this.$message.error("Unauthorized");
            } else if (result.data.errorCode === 403) {
              this.$message.error("Forbidden");
            } else if (result.data.errorCode === 404) {
              this.$message.error("Not Found");
            } else if (result.message == 44 || result.message == 40) {
              this.$message.error("身份过期,请重新登录");
              this_1.$router.push("/Login");
            }
          });
        }
      });
    },
    copyOrderId(orderId) {
      var input = document.createElement("input"); // 创建input对象
      input.value = orderId; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("成功复制到剪贴板");
    },

    handleSelectChange(value) {
      // console.log(value);
      this.form.setFieldsValue({
        note: `Hi, ${value === "male" ? "man" : "lady"}!`,
      });
    },
  },
};
</script>